<template>
<div>
  <FullScreenDialog
    v-model="invoiceDialog"
    :title="title"
    :routeFather="pathToGoBack"
    :routeFatherName="pathName"
    :otherGoBackParams="{ params: { filters: this.filters } }"
  >
    <template v-slot:header-actions>
      <div>
        <v-subheader>
          <v-col>
            <v-row>
              <v-chip class="mr-7" :color="statusColor">
                {{ statusLabelColor }}
              </v-chip>
              <v-chip :color="typeColor">
                {{ typeLabelColor }}
              </v-chip>
            </v-row>
          </v-col>
        </v-subheader>
      </div>
      <v-btn
        icon
        @click="toggleBarcode"
        :color="!!editOptionByAdmin ? 'red' : ''"
        v-if="variant == 'detail'"
      >
        <v-icon>mdi-shield-account-variant</v-icon>
      </v-btn>
    </template>
    <template v-slot:toolbar-extension>
      <v-tabs v-model="tab" align-with-title>
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab v-for="tab in invoicesTab" :key="tab.key">{{ tab.title }}</v-tab>
      </v-tabs>
    </template>

    <template v-slot:content>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="tabItem in invoicesTab" :key="tabItem.key">
          <v-card flat>
            <v-card-text class="pa-0">
              <component
                v-bind="currentProps"
                :is="tabItem.component"
              ></component>
              <div class="py-3" v-if="variant != 'detail' || (!!scheduledPayments && scheduledPayments.length > 0)">
                <PartialPayment
                  :amount="amountToPay"
                  :objectId="objectId"
                  :scheduledPayments="scheduledPayments"
                  @paidPartial="fetchInvoice"
                  @schedulePaymentsCreated="fetchInvoice"
                ></PartialPayment>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>

    <template v-slot:footer-actions>
      <div>
        <v-spacer></v-spacer>
        <v-btn text color="error" @click="close"
          >Chiudi</v-btn
        >

        <v-btn
          v-if="currentComponent === 'GeneralFormTab' && (variant != 'detail' || editOptionByAdmin)"
          text
          color="default"
          :disabled="!formValid || isLicenseBlocked"
          @click="submitForm"
          >Salva</v-btn
        >
        <v-btn text color="error" @click="payAndClose" v-if="variant === 'edit' && (scheduledPayments != undefined && scheduledPayments.length == 0)"
          :disabled="isLicenseBlocked"
          >Paga totale & Chiudi</v-btn
        >
      </div>
    </template>
  </FullScreenDialog>

  <StandardDialog
    v-model="dialogBarcode"
    title="Area Riservata Sviluppatori" 
    :dialog-height="null"
    dialog-max-width="500px"
  >
    <ManualBarcodeInput
      v-model="operatorBarcode"
      scan-your-barcode-message="Immettere Codice" 
      @confirm="activeBarcode"
    >
    </ManualBarcodeInput>
  </StandardDialog>


  <StandardDialog
    v-model="errorDialogBarcode"
    :dialog-height="null"
    :dialog-width="null"
  >
    <v-alert
      class="mt-3"
      border="right"
      colored-border
      type="error"
      elevation="2"
    >
      {{messagErrorDialog}}
    </v-alert>
  </StandardDialog>

</div>
</template>

<script>
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import StandardMenu from "@/components/common/StandardMenu.vue";
import Timeline from "./form/Timeline.vue";
import GeneralFormTab from "./form/GeneralFormTab.vue";
import invoiceService from "@/services/invoices/invoices.service.js";
import invoiceForm from "@/services/invoices/invoice.form.js";
import PartialPayment from "@/components/common/PartialPayment.vue";
import operatorService from '@/services/operators/operators.service.js'
import ManualBarcodeInput from '@/components/common/ManualBarcodeInput.vue';
import StandardDialog from '@/components/common/StandardDialog.vue';
import Vue from "vue";

export default {
  name: "InvoicesToPayEditForm",
  components: {
    FullScreenDialog,
    StandardMenu,
    Timeline,
    GeneralFormTab,
    PartialPayment,
    StandardDialog,
    ManualBarcodeInput,
  },
  data: function () {
    return {
      id: undefined,
      tab: null,
      formValid: false,
      loadingInvoice: false,
      invoiceDialog: this.openDialog,
      title: "",
      typeColor: "",
      typeLabelColor: "",
      statusColor: "",
      statusLabelColor: "",
      invoicesTab: [
        {
          key: 0,
          title: "Dati di fatturazione",
          name: "general",
          component: "GeneralFormTab",
        },
      ],
      invoice: undefined,
      originalUrl: window.location.pathname,
      invoiceElements: [],
      currentComponent: "",
      bus: new Vue(),
      amountToPay: undefined,
      scheduledPayments: undefined,
      objectId: undefined,
      dialogBarcode: false,
      editOptionByAdmin: false,
      operatorBarcode: undefined,
      errorDialogBarcode: false,
      messagErrorDialog: '',
      isLicenseBlocked: undefined,
    };
  },
  mounted() {
    this.changeTabBasedOnProp(this.tabName);

    this.id = this.$route.params.id;
    if (this.id) {
      this.fetchInvoice();
    }

    let self = this;
    invoiceForm.on("update", function (data) {
      if(!!data.invoice && data.invoice.amount != undefined){
        self.amountToPay = Number(data.invoice.amount)
      }
      if(!!data.invoice && data.invoice.id != undefined){
        self.objectId = Number(data.invoice.id)
      }
      self.invoice = data.invoice;
    });

    self.valid = invoiceForm.valid;
    invoiceForm.on("valid-change", function (valid) {
      self.formValid = valid;
    });

    this.bus.$on("reload-invoice", self.fetchInvoice);
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
    pathToGoBack: {
      type: String,
      default: "/payments/invoicesToPay/list",
    },
    pathName: {
      type: String,
    },
    tabName: {
      type: String,
      default: 'general'
    },
    filters : {
      type: Array
    },
    variant: {
      type: String,
      default: "detail",
      validator: function (value) {
        if (!["edit", "detail"].includes(value)) {
          return "invalid variant";
        } else {
          return true;
        }
      },
    },
  },
  methods: {
    changeTabBasedOnProp(tabName) {
      for (let i = 0; i < this.invoicesTab.length; i++) {
        if (this.invoicesTab[i].name == tabName) {
          this.tab = i;
          break;
        }
      }
    },
    submitForm() {
      delete this.invoice.invoiceGenericElements;
      delete this.invoice.invoiceItems;
      delete this.invoice.invoiceServices;
      delete this.invoice.invoiceCustomer;
      delete this.invoice.invoiceSupplier;
      delete this.invoice.invoiceAmounts;
      invoiceService.update(this.invoice).then((result) => {
        this.close()
      });
    },
    payAndClose() {
      invoiceService.pay(this.invoice).then((result) => {
        this.$delegates.snackbar("Fattura pagata correttamente");
        this.close()
      });
    },
    close() {
      if(!!this.pathName)
        this.$router.push({name: this.pathName, params: { filters: this.filters }})
      else
        this.$router.push({path:this.pathToGoBack})
    },
    activeBarcode(){
      operatorService.canPerformOperation(this.operatorBarcode, "Invoices").then((result) => {
        if (result) {
          this.editOptionByAdmin = true
        } else {
          this.messagErrorDialog = 'Operatore non autorizzato'
          this.errorDialogBarcode = true
        }
      }).catch(() => {
        this.messagErrorDialog = 'Codice non riconosciuto'
        this.errorDialogBarcode = true
      })
      this.operatorBarcode = undefined
      this.dialogBarcode = false
    },
    toggleBarcode(){
      this.dialogBarcode = !this.dialogBarcode
    },
    fetchInvoice() {
      this.loadingInvoice = true;
      invoiceForm.resetInvoice();
      invoiceService.get(this.id).then((invoice) => {
        this.loadingInvoice = false;
        this.currentProps;
        invoiceForm.updateObject(invoice);
        if(!!invoice.paymentCredits)
          this.scheduledPayments = [...invoice.paymentCredits]
        this.title =
          "Dettaglio Fattura " +
          invoice.internalCode +
          " - " +
          invoice.description +
          " - " +
          invoice.lastname +
          " " +
          (invoice.firstname ? invoice.firstname : "");
        invoice.description;
        this.handleStatusColor(invoice);
        this.handleTypeColor(invoice);
      });
    },
    handleStatusColor(invoice) {
      if (invoice.status == "paid") {
        if (invoice.type == "warehouse") {
          this.statusLabelColor = "Pagata";
          this.statusColor = "red lighten-3";
        } else {
          this.statusLabelColor = "Pagata";
          this.statusColor = "green lighten-3";
        }
      } else if (invoice.status == "cancelled") {
        this.statusLabelColor = "Cancellata";
        this.statusColor = "red";
      } else if (invoice.status == "beingPaid") {
        this.statusLabelColor = "Pagamento a Rate";
        this.statusColor = "orange lighten-2";
      } else if (invoice.status == "pending" && invoice.type == "warehouse") {
        this.statusLabelColor = "Pagamento da effettuare";
        this.statusColor = "red lighten-3";
      } else if (invoice.status == "pending") {
        this.statusLabelColor = "Fattura in attesa";
        this.statusColor = "blue lighten-3";
      }
    },
    handleTypeColor(invoice) {
      if (invoice.type == "warehouse") {
        this.typeLabelColor = "Automatica magazzino";
        this.typeColor = "gray lighten-3";
      } else if (invoice.type == "restaurant") {
        this.typeLabelColor = "Manuale Ristorante";
        this.typeColor = "gray lighten-3";
      } else if (invoice.type == "other") {
        this.typeLabelColor = "Altro";
        this.typeColor = "gray lighten-3";
      }  else if (invoice.type == "saloon") {
        this.typeLabelColor = "Manuale Salone";
        this.typeColor = "gray lighten-3";
      } else if (invoice.type == "cashDesk") {
        this.typeLabelColor = "Automatica in cassa";
        this.typeColor = "gray lighten-3";
      } else if (invoice.type == "otherToPay") {
        this.typeLabelColor = "Manuale";
        this.typeColor = "gray lighten-3";
      } else if (invoice.type == "creditNoteToPay") {
        this.typeLabelColor = "Nota a Credito";
        this.typeColor = "gray lighten-3";
      }
    },
  },
  computed: {
    currentProps() {
      if (this.currentComponent === "GeneralFormTab") {
        return { invoice: this.invoice, variant: this.variant, editOptionByAdmin: this.editOptionByAdmin };
      } else if (this.currentComponent === "Timeline") {
        return { invoice: this.invoice, variant: this.variant, editOptionByAdmin: this.editOptionByAdmin };
      } else return;
    },
  },
  watch: {
    openDialog(newVal) {
      this.invoiceDialog = newVal;
    },
    tab(newVal) {
      this.currentComponent = this.invoicesTab[newVal].component;
      this.currentProps;
      try {
        window.history.pushState(
          "Tab",
          "Tab",
          this.originalUrl + "?tabName=" + this.invoicesTab[newVal].name
        );
      } catch (err) {
        console.log("try setting the tab name but something goes wrong");
      }
    },
  },
  isLicenseBlocked: {
    bind: 'isLicenseBlocked'
  }
};
</script>